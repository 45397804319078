// src/components/Hero.js
import React from 'react';
import '../Styles/Hero.css';
import wineImage from '../Images/winetasting.jpg';

const Hero = () => {
  return (
    <section id="home" className="hero">
      <div className="hero-content">
        <div className="hero-text">
          <div>
            <h1>Join Our Inclusive  Wine Club!</h1>
            <button className="cta-button">Sign me up</button>
          </div>
          <div>
            <p className="cta-description">Welcome to a leading wine club in Toronto, where we provide wine expertise in a lively and interactive setting. Join us to enhance your wine knowledge, socialize with new acquaintances, or savor the company of friends who appreciate wine just like you.</p>
            {/* <p className="disclaimer">Disclaimer: Terms and conditions may apply. All government taxes associated with this prize are solely the responsibility of the winner.</p> */}
          </div>
        </div>
        <div className="hero-image">
          <img src={wineImage} alt="Luxury SUV" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
