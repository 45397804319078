// src/Header.js
import React from 'react';
import wineClubLogo from '../Images/WineClubLogov22023.png';
import '../Styles/Header.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faInstagram, faEnvelope);



const Header = () => {
  return (
    <header>
      <nav className='navigation-bar'>
        <ul>
          <li><img src={wineClubLogo} alt="Wine Club Logo" className="header-logo"/></li>
          <li><FontAwesomeIcon icon={['fab', 'instagram']} size="2x" /></li>
          <li><FontAwesomeIcon icon="envelope" size="2x" /></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
