// src/App.js
import React from 'react';
import 'normalize.css';
import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
// import About from './Components/About';
// import Events from './Components/Events';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      {/* <About />
      <Events /> */}
      <Footer />
    </div>
  );
}

export default App;
